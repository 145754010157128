import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useLoading } from '@config/loadingContext';
import { Tuser } from '@interfaces/globals.types';
type AllModalType = {
  name: boolean;
  reorderAll: boolean;
  group: boolean;
};
interface Props {
  userType: string;
  userItem: Tuser;
  setUserItem: Dispatch<SetStateAction<Tuser>>;
  UseUserAPI: (kind: 'new' | 'del' | 'edit', item: Tuser) => void;
  closeModal: () => void;
}

export default function UserModal({
  userType,
  userItem,
  setUserItem,
  UseUserAPI,
  closeModal,
}: Props) {
  /**
   * 옵션명 onchange 액션
   * @param index selecter index
   * @constant firstInput
   */
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setUserItem((prev) => {
      return { ...prev, [name]: value };
    });
  };
  return (
    <>
      <div className="modalY-overlay" onClick={() => closeModal()}></div>
      <div id="standardproduct-window" className="preview-window preview-800">
        {/* <button onClick={() => goDummy()}>고더미</button> */}
        <div
          className="closeBtn"
          onClick={() => {
            closeModal();
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {userItem && (
          <>
            <div className="bg-f7f7f7 p-5">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    <span>
                      <i>계정정보</i>
                    </span>{' '}
                    {userType === 'userNew' ? '생성' : ' 수정'}
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable rename"
                          cellSpacing="0"
                        >
                          <tbody>
                            <tr>
                              <td className="col-3 col-sm-4  col-xs-12">
                                등급
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <select
                                      className="form-control"
                                      name="userClass"
                                      value={userItem.userClass}
                                      onChange={(e) => handleChange(e)}
                                    >
                                      <option value="firstDegreeManager">
                                        최고 관리자
                                      </option>
                                      <option value="general manager">
                                        사이트 관리자
                                      </option>
                                      <option value="common manager">
                                        일반 관리자
                                      </option>
                                      <option value="manager">직원</option>
                                    </select>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4  col-xs-12">
                                아이디
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control renameTitle"
                                      placeholder="아이디"
                                      name="userId"
                                      value={userItem.userId}
                                      onChange={(e) => handleChange(e)}
                                      required
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4  col-xs-12">
                                비밀번호
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <input
                                      type="password"
                                      className="form-control renameTitle"
                                      placeholder="비밀번호"
                                      name="userPwd"
                                      value={userItem.userPwd}
                                      onChange={(e) => handleChange(e)}
                                      required
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4  col-xs-12">
                                이름
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control renameTitle"
                                      placeholder="이름"
                                      name="userName"
                                      value={userItem.userName}
                                      onChange={(e) => handleChange(e)}
                                      required
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  <div
                    className="price-start-btn btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      UseUserAPI(
                        userType === 'userNew' ? 'new' : 'edit',
                        userItem,
                      );
                    }}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      수정완료
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
